@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.koalafi-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input[type='radio'] {
    -webkit-appearance: none;
    cursor: pointer;
    transition: 0.1s;
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $koalafi-white;
    margin: 0 !important;
    border: 0.1rem solid $koalafi-gray;
    &:checked {
      -webkit-box-shadow: inset 0 0 2px $koalafi-rich-black;
      -moz-box-shadow: inset 0 0 2px $koalafi-rich-black;
      box-shadow: inset 0 0 2px $koalafi-rich-black;
    }
  }
  &--required {
    -webkit-box-shadow: 0 0 3px 1px $koalafi-dark-strawberry;
    -moz-box-shadow: 0 0 3px 1px $koalafi-dark-strawberry;
    box-shadow: 0 0 3px 1px $koalafi-dark-strawberry;
  }

  label {
    margin: 0 0.5rem;
    font-family: $inter-SemiBold-font;
    &:first-letter {
      text-transform: capitalize;
    }
    //line-height: 1.25rem;
  }
  .radio-button-box {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    max-width: fit-content;
    position: relative;
    max-height: fit-content;
    .checked {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: $koalafi-gray;
      position: absolute;
      left: 6px;
      -webkit-box-shadow: 0 0 3px 1px $koalafi-mist;
      -moz-box-shadow: 0 0 3px 1px $koalafi-mist;
      box-shadow: 0 0 3px 1px $koalafi-mist;
    }
  }
}
