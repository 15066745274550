@import '../../../assets/styles/koalafi-theme-colors';

.intro-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    max-width: 25%;
    height: 5rem;
    margin: auto;
    margin-bottom: 0 !important;
  }
}

.reverse {
  h3 {
    color: $koalafi-white;
  }
}

@media (max-width: 51.875rem) {
  .intro-section {
    svg {
      height: 2rem;
    }
  }
}
