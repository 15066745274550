@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.koalafi-check-button {
  &[type='checkbox'] {
    border-radius: 50%;
    background-color: $koalafi-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    transition: 0.3s;
    margin-left: 0;
    &:after {
      content: '\E829';
      font-family: $fontello-font;
      font-size: 1.5rem;
      left: 0.25rem;
      position: relative;
      top: 0.3rem;
      visibility: hidden;
      color: $koalafi-dark-mint;
    }
    &:checked {
      background-color: $koalafi-white;
      &:after {
        animation: a 0.3s infinite;
        visibility: visible;
      }
    }
  }
  box-shadow: 0 0 0 0.1rem $koalafi-gray;
  &--required {
    box-shadow: 0 0 0 2px $koalafi-dark-strawberry;
  }
}
