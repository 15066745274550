@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.autopay-details {
  > form {
    color: $koalafi-rich-black;
    h2 {
      padding-bottom: 0.5rem;
    }
    .checkbox {
      display: inline;
    }
  }
  .estimates {
    p {
      &:nth-child(even) {
        text-align: right;
        font-size: 1.2rem;
        line-height: 1.75rem;
        font-family: $inter-SemiBold-font, sans-serif;
        font-weight: 600;
        margin-bottom: 2rem;
      }
    }
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin: 0;
    max-width: 100%;
  }
  button {
    width: 100%;
  }
  .text-field {
    margin-bottom: 1rem;
  }

  .option-grp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    a {
      margin-left: 2.4rem;
      @media (max-width: 600px) {
        margin-left: 3rem;
      }
    }
  }

  hr {
    border: 1px solid $koalafi-mist;
    margin-bottom: 3rem;
  }
}

#autopay-red-font {
  color: #dc1717;
}
