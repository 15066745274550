@import '../../../assets/styles/koalafi-theme-colors';

.ineligible {
  &--container {
    margin-left: auto;
    margin-right: auto;
    max-width: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    h3 {
      font-size: 150%;
      padding-bottom: 1rem;
    }
    p {
      text-align: center;
    }
  }
  &--icon {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}
