@import '../../../assets/styles/koalafi-theme-colors';

.switch {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 33px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
}
.switch::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  top: 4px;
  left: 4px;
  transition: all 0.3s;
}

.checkbox:checked + .switch::after {
  left: 46px;
}
.checkbox:checked + .switch {
  background-color: $koalafi-mint;
}
.checkbox {
  display: none;
}
