@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.personal-details {
  p {
    grid-column: 1/5;
    text-align: center;
    margin-bottom: 1rem;
    color: $koalafi-indigo;
  }

  form {
    display: grid;
    grid: auto / 52% 10% 10% 22%;
    grid-column-gap: 2%;
    > div {
      grid-column: 1/5;
      margin-bottom: 1rem;
    }
    .consent {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.8rem;
      }
      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    .city {
      grid-column: 1/2;
    }
    .state {
      grid-column: 2/4;
    }
    .zip {
      grid-column: 4/5;
    }
    .consent-links {
      display: grid;
      grid-column: 1/5;
      grid-template-columns: 50% 50%;
      grid-row-gap: 1rem;
      padding: 0.5rem 3rem 1rem;
    }

    button {
      grid-column: 1/5;
    }
  }
  .alimony-disclosure {
    padding-bottom: 0;
  }

  &-sub-header {
    margin-top: 2rem;
  }

  &-note {
    color: $koalafi-rich-black !important;
  }
}

.koalafi-info-box {
  grid-column: 1/5;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 600px) {
  .personal-details {
    form * {
      grid-column: 1/5 !important;
    }
  }
}
