@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.header {
  display: flex;
  justify-content: center;
  height: $header-height;
  color: $koalafi-white;
  align-items: flex-end;
  background-color: transparent;
  p {
    color: $koalafi-indigo;
  }
}

#logo-wrapper {
  display: flex;
  width: 100%;
  max-width: 51rem;
  align-items: center;
  align-self: center;
  height: $header-height;
}

#header-company-logo {
  margin: 0;
  height: 30px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 10rem;
    margin: 0 0 0 0.5rem;
  }
}

.nav-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.dealer-name,
.nav-link {
  padding: 0.72rem;
  text-align: center;
}

.centered {
  position: absolute;
}

.right {
  position: absolute;

  @media (max-width: 26.25rem) {
    position: relative;
  }
}
