@import '../../../assets/styles/fonts';

.transition {
  text-align: center;
  &--icon-group {
    display: grid;
    grid-template-columns: 20.25rem 21.9rem;
    grid-column-gap: 1.5rem;
    grid-row-gap: 3rem;
    justify-content: center;
    justify-items: left;
    margin-bottom: 4rem;
    span {
      display: flex;
      align-items: baseline;
    }
    > span > svg {
      content: '';
      box-shadow: 0.1875rem 0.1875rem 0.1875rem rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      border-radius: 50%;
      width: 6rem;
    }
    > span > div {
      margin-top: 1rem;
    }
  }
  &--icon-text {
    font-size: 1.5rem;
    font-weight: 700;
    position: relative;
    top: -2.25rem;
    margin-left: 1rem;
  }
  form > button {
    left: 0;
    right: 0;
    bottom: -36px;
    width: 50%;
    margin: 0 auto;
  }
  form > input {
    display: none;
  }
}

@media (max-width: 51.875rem) {
  .transition {
    h1 {
      padding-top: 1rem;
    }
    h6 {
      line-height: 2rem;
      width: 55%;
      margin: 0 auto;
    }
    &--icon-group {
      grid-template-columns: auto;
    }
  }
}
