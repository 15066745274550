@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/variables';

.footer {
  display: flex;
  position: absolute;
  width: 100%;
  min-height: $footer-height;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 0.5rem 1rem 0.5rem;
  background-color: $koalafi-white;
  box-shadow: 0 0 0, 3px -2px 3px $koalafi-light-gray;
  p {
    color: $koalafi-indigo;
  }
  .contact-info {
    display: flex;
    align-items: center;
    p {
      margin-left: 0.5rem;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    width: 1rem;
  }
  .info-koalafi {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }

  .info-box,
  .c-address {
    padding-bottom: 0px;
  }
  .disclosure {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 51.875rem) {
  .footer {
    .icon-wrapper {
      width: 1rem;
    }
    .info-koalafi {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .contact-info {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

@media (max-width: 26.25rem) {
  .footer {
    .c-address {
      display: none;
    }
  }
}
