@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.payment-schedule {
  div {
    margin-bottom: 0.5rem;
  }
  .sub-head-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 1rem !important;
    line-height: 1.2rem !important;
    letter-spacing: 0.02em;
  }
  .sub-head {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
    letter-spacing: 0.02em;
  }
  .borrower-creditor {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
  }
  .marketing-group {
    display: grid;
    grid-template-columns: 6.5rem auto;
    margin-top: 1rem;
  }
  hr {
    width: 100%;
    margin: 1rem 0;
    border: 0.5px solid $koalafi-white;
  }
  .tc-text {
    padding: 1rem;
    color: $koalafi-white;
    div > span,
    p > span {
      font-weight: 600;
    }
  }
  .tc-box {
    border-radius: 0.5rem;
    background-color: $koalafi-mist;
    div,
    p {
      color: $koalafi-cool-gray;
      line-height: 0.8rem;
    }
  }
  .document-link {
    color: $koalafi-royal-blue;
    &:hover {
      color: $koalafi-indigo;
    }
  }
  .checkbox-group {
    padding: 1rem 0;
    .checkbox {
      margin-right: 1rem;
    }
    .tc-link-group {
      display: grid;
      grid-column: 1/5;
      grid-template-columns: 50% 50%;
      grid-row-gap: 1rem;
      padding: 0.5rem 3rem 1rem;
      .document-link {
        border: none;
        text-align: left !important;
        font-family: $inter-SemiBold-font, sans-serif !important;
        background-color: transparent;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        line-height: 1.1rem;
        letter-spacing: 0.02em;
        color: $koalafi-royal-blue;
        height: 1rem;
        width: 100%;
        &:hover {
          color: $koalafi-indigo;
          text-decoration: none;
        }
      }
    }
  }
  button {
    width: 100%;
    margin-top: 3rem;
  }
  .bio {
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-bottom: 1rem;
  }
  .sub-text {
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 400;
    margin-top: 0.5rem;
  }
  #terms-agreement-error {
    display: flex;
    height: 1.25rem;
    margin-top: 0.5rem;
    justify-content: center;
  }
}
