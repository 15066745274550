@import 'assets/styles/koalafi-theme-colors';
@import 'assets/styles/fonts';
@import 'assets/styles/variables';

html,
body {
  font-size: 1rem;
  height: 100%;
  font-family: $inter-Regular-font, sans-serif;
}

.main {
  min-height: calc(100% - #{$footer-height} - #{$header-height} - 0.5rem);
}

#root {
  height: 100%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 51.875rem) {
  html {
    font-size: 80%;
  }
  .main {
    min-height: calc(100% - #{$footer-height} - #{$header-height} - 3rem);
  }
}

@media (max-width: 48rem) {
  html {
    font-size: 80%;
  }
  .main {
    min-height: calc(100% - #{$footer-height} - #{$header-height} - 1.7rem);
  }
}

@media (max-width: 26.25rem) {
  html {
    font-size: 80%;
  }
  .main {
    min-height: calc(100% - #{$footer-height} - #{$header-height} - 3rem);
  }
}
