@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.send-verification-code {
  form {
    display: grid;
    grid: auto;
    grid-column-gap: 2%;
    > div {
      grid-column: 1/5;
      margin-bottom: 1rem;
    }
    button {
      grid-column: 1/5;
      margin-top: 2%;
    }
  }
  p {
    margin-bottom: 1rem;
  }
}
