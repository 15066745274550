@import '../../../assets/styles/koalafi-theme-colors';

.address-line-1 {
  .address-predictions {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px $koalafi-gray;
    box-shadow: 0 0 5px $koalafi-gray;
    background-color: $koalafi-white;
    width: 100%;
    div:hover {
      background-color: $koalafi-mist;
    }
    .prediction {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 2.3rem;
      p {
        margin: 0 0 0 1rem;
        color: $koalafi-rich-black;
        line-height: 1.1rem;
      }
    }
  }
}
