.identity-confirmation {
  h2 {
    padding-bottom: 2rem;
  }
  p {
    margin: 0 0 1rem;
  }
  form {
    display: flex;
    flex-direction: column;

    .field {
      margin-bottom: 1rem;
    }
  }
}
