@import '../../../assets/styles/koalafi-theme-colors';

.koalafi-modal {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0.3rem;
  outline: none;
  padding: 1rem;
  margin: 4rem auto;
  max-width: 550px;
  text-align: center;

  #modal--affirmAction {
    background-color: $koalafi-royal-blue;
  }
  p {
    margin: 2rem 0 2rem;
  }
  #modal--dismissAction {
    background-color: $koalafi-gray;
  }
  #modal--dismissAction-single-button {
    background-color: $koalafi-royal-blue;
  }
}

.ReactModal__Overlay {
  background-color: rgba(000, 000, 000, 0.5) !important;
  transition: opacity 500ms ease-in-out;
  &--after-open {
    opacity: 1;
    z-index: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Body {
  &--open {
    overflow: hidden;
  }
}
