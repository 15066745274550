@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.form-select {
  position: relative;
  span {
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.1rem;
    text-align: start;
    margin-top: 5px;
    text-transform: uppercase;
    color: $koalafi-dark-strawberry;
  }
}

.select-required {
  &:after {
    font-family: $fontello-font;
    color: $koalafi-dark-strawberry;
    content: '\E802';
  }
}
.select-valid {
  &:after {
    font-family: $fontello-font;
    color: $koalafi-indigo;
    content: '\E827';
  }
}

@media (max-width: 26.25rem) {
  .select-required {
    &:after {
      content: unset !important;
    }
  }
  .select-valid {
    &:after {
      content: unset !important;
    }
  }
}

.select-required:after,
.select-valid:after {
  font-family: $fontello-font;
  position: absolute;
  right: 1rem;
  top: 1.4rem;
}

.state {
  .select-valid:after,
  .select-required:after {
    right: 45px;
    font-size: 1rem;
    background-color: red;
  }
}
