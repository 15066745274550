@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.ready-for-delivery {
  * {
    text-align: center;
  }
  button {
    width: 100%;
  }
  p {
    margin: 2rem 0 3rem;
    font-family: $gilroy-Regular-font;
    font-size: 1.2rem;
    letter-spacing: 0.02rem;
    line-height: 1.75rem;
  }
}
