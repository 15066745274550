@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.approved {
  text-align: center;
  max-width: 40rem;
  &--container {
    display: flex;
    flex-direction: column;
    .next-step-btn {
      width: 100%;
      text-align: center;
    }
    * {
      margin: 0 auto;
    }
    h2 {
      padding-top: 2rem;
      padding-bottom: 0;
    }
  }
  &--msg {
    padding-top: 0.1rem;
  }
  &--icon {
    animation: bounce 2s;
  }
  hr {
    border-top: 1px solid white;
    width: 40%;
    margin-bottom: 1rem;
  }
  button {
    width: 100%;
  }
  p {
    font-family: $gilroy-Regular-font;
    font-size: 1.2rem;
    line-height: 1.75rem;
    letter-spacing: 0.02em;
    margin-bottom: 2rem;
  }
}
