@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.purchased-amount {
  table {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    background-color: $koalafi-mist;
    z-index: 1;
    thead {
      flex: 1;
      font-family: $inter-Regular-font;
      color: $koalafi-cool-gray;
      text-transform: uppercase;
      font-size: 0.67rem;
      line-height: 0.8rem;
      letter-spacing: 0.05em;
      tr {
        display: flex;
        justify-content: space-evenly;
        th {
          flex: 1;
          flex-direction: column;
          align-self: center;
          padding: 0.3rem;
        }
      }
    }
    tbody {
      flex: 1;
      flex-direction: column;
      border-radius: 0.3125rem;
      font-family: $inter-SemiBold-font;
      color: $koalafi-rich-black;
      font-size: 1rem;
      line-height: 1.1rem;
      letter-spacing: 0.02em;
      tr {
        display: flex;
        justify-content: space-evenly;
        border-top: 1px solid $koalafi-white;
        padding: 1rem 0 1rem 0;
        td {
          flex: 1;
          text-align: center;
          align-self: center;
        }
        &:last-child td {
          &:first-child {
            border-bottom-left-radius: 0.3125rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.3125rem;
          }
        }
      }
    }
    #purchased-amount {
      &--salesRep {
        margin-bottom: 1rem;
      }
    }
  }
  .more-info {
    cursor: pointer;
    &:after {
      content: '\E86A';
      font-family: $fontello-font;
      font-size: 1rem;
    }
  }
  button {
    width: 100%;
  }
  .sales-rep {
    margin-bottom: 1rem;
  }
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3% 0 3%;
    p {
      padding-bottom: 1%;
    }
  }
  .horizontal {
    display: flex;
    flex-direction: row;
  }
  .table-border {
    padding: 1rem 2rem;
    background-color: $koalafi-mist;
    border-radius: 0.3125rem;
  }
  .selection-required {
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.1rem;
    text-align: start;
    margin-top: 5px;
    text-transform: uppercase;
    color: $koalafi-dark-strawberry;
  }
}

//26.25rem
@media (max-width: 29.25rem) {
  .purchased-amount {
    table {
      width: 95vw;
      // prettier-ignore
      margin-left: - calc($form-wrapper-padding / 1.3);
      thead {
        tr {
          th {
            padding: 0;
            margin: 0.3rem;
            width: 4rem;
          }
        }
      }
      tbody {
        font-size: 0.8rem;
        tr {
          td {
            margin: 0.3rem 0.75rem 0 0.3rem;
            max-width: 4rem;
          }
        }
      }
    }

    .table-border {
      padding: 0;
    }
  }
}
