@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/fonts';

.consent-links {
  flex: 1;
  align-self: center;
  button {
    text-align: center;
    background-color: transparent;
    text-transform: capitalize;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.1rem;
    letter-spacing: 0.02em;
    font-family: $inter-SemiBold-font;
    color: $koalafi-royal-blue;
    &:hover {
      color: $koalafi-indigo;
      text-decoration: none;
    }
  }
}
.verify-phone-code {
  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  p {
    margin-bottom: 1rem;
  }
}
