@import '../../../assets/styles/koalafi-theme-colors';
@import '../../../assets/styles/variables';

.koalafi-form-wrapper {
  background-blend-mode: darken;
  border-radius: 8px;
  position: relative;
  padding: $form-wrapper-padding;
  max-width: 51rem;
  margin: auto;
  color: $koalafi-white;
  background-color: $koalafi-white;
  &:first-child {
    margin-top: 0;
  }
}
