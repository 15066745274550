.electronic-communications {
  h6 {
    padding-bottom: 0.5rem;
    margin: 1rem 0 0.5rem;
  }
  ul {
    list-style-type: disc;
    li {
      list-style-type: disc;
      line-height: 1.3rem;
      margin-bottom: 0.5rem;
    }
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
}
