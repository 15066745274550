@import '../../../assets/styles/koalafi-theme-colors.scss';
@import '../../../assets/styles/fonts.scss';

.customer-details {
  h2 {
    padding-bottom: 2rem;
  }
  p {
    line-height: 1.75rem;
  }
  form {
    display: grid;
    grid-column-gap: 2%;
    > div {
      grid-column: 1/5;
      margin-bottom: 2rem;
    }
    .consent {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      span {
        margin-left: 0.8rem;
      }
    }
    .city {
      grid-column: 1/2;
    }
    .state {
      grid-column: 2/4;
    }
    .zip {
      grid-column: 4/5;
    }
    .consent-links {
      display: grid;
      grid-column: 1/5;
      grid-template-columns: 50% 50%;
      grid-row-gap: 1rem;
      padding: 0.5rem 3rem 1rem;
    }
    button {
      grid-column: 1/5;
    }
  }
  .customer-details-bio {
    display: flex;
    flex-direction: row;
    .column {
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      > div {
        padding: 0.3rem;
        .label {
          font-family: $inter-SemiBold-font !important;
          color: $koalafi-indigo;
        }
        p {
          padding: 0;
        }
      }
    }
  }
  #customer-details-edit-icon {
    position: absolute;
    margin-left: 85%;
    height: 1.5rem;
    width: 1.5rem;
    &:hover {
      stroke: $koalafi-indigo;
      cursor: pointer;
    }
  }
}

@media (max-width: 600px) {
  .customer-details {
    form * {
      grid-column: 1/5 !important;
    }
    #customer-details-edit-icon {
      margin-left: 75%;
    }
  }
}
